/* custom-quiz-styles.css */
.react-quiz-container {
    font-family: inherit !important;
  }
  
  .react-quiz-container {
    background: linear-gradient(to right, #f0fdf4, #d1fae5) !important;
    border-radius: 0.75rem !important;
    padding: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }
  
  .react-quiz-container .questionWrapper .questionWrapperBody {
    font-size: 1.25rem !important;
    color: #065f46 !important;
  }
  
  .react-quiz-container .answerBtn {
    background-color: white !important;
    border: 2px solid #10b981 !important;
    color: #047857 !important;
    border-radius: 0.5rem !important;
    padding: 0.75rem 1rem !important;
    margin: 0.5rem 0 !important;
    transition: all 0.2s !important;
  }
  
  .react-quiz-container .answerBtn:hover {
    background-color: #ecfdf5 !important;
  }
  
  .react-quiz-container .answerBtn.correct {
    background-color: #10b981 !important;
    color: white !important;
  }
  
  .react-quiz-container .answerBtn.incorrect {
    background-color: #f87171 !important;
    color: white !important;
  }
  
  .react-quiz-container .nextQuestionBtn, .react-quiz-container > div > .startQuizWrapper > .btn {
    background-color: #10b981;
    color: white !important;
    border: none !important;
    border-radius: 0.5rem !important;
    padding: 0.75rem 1.5rem !important;
    font-size: 1rem !important;
    cursor: pointer !important;
    transition: background-color 0.2s !important;
  }
  
  .react-quiz-container .nextQuestionBtn:hover {
    background-color: #047857 !important;
  }
  
  .react-quiz-container .result-answer-wrapper {
    background-color: #f0fdf4 !important;
    border-radius: 0.5rem !important;
    padding: 1rem !important;
    margin-bottom: 1rem !important;
  }
  
  .react-quiz-container .correct-answer {
    color: #047857 !important;
  }
  
  .react-quiz-container .quiz-synopsis {
    margin-bottom: 1rem !important;
    font-size: 1.1rem !important;
    color: #374151 !important;
  }