@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@font-face {
  font-family: 'KfgqpcHafsUthmanicScriptRegular-1jGEe';
  src: url('../public/fonts/KfgqpcHafsUthmanicScriptRegular-1jGEe.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Decotype Thuluth';
  src: url('../public/fonts/decotype-thuluth.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@import 'flowbite/dist/flowbite.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: rgb(249 250 251); /* bg-gray-50 */
  overscroll-behavior: none;
}

/* Add dark mode override */
body.dark {
  background-color: #0A0A0A;
}

#dailyDuaCard > div, #dailyHadithCard > div, #prayerTimesCard > div, #dateTimeCard > div, #quranProgressCard > div, #goalsCard > div, #adhkarTrackerCard > div {
  padding:0px;
  gap:0px;
}

#adhkarTrackerCard > div, #goalsCard > div, #prayerTimesCard > div, #dailyDuaCard > div, #addGoalPlannerCard > div {
  justify-content: start;
}

.font-uthmani {
  font-family: 'KfgqpcHafsUthmanicScriptRegular-1jGEe', serif;
  direction: rtl;
  text-align: right;
  line-height: 2.5;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'liga' 1, 'calt' 1;
}

/* Option 1: Using the card class */
.card > div {
  justify-content: flex-start !important;
}




.deenToolsCard > div {
  align-items: center !important;
}

#dateTimeCard > div {
  align-items: center !important;
  justify-content: space-between !important;
}

.quran-main-content {
  margin-left: 0; /* Default for small screens */
}

/* Apply margin-left only on large screens */
@media (min-width: 1024px) {
  .quran-main-content {
    margin-left: 350px;
  }
}

.quran-sidebar {
  width: 100%; /* Default width for mobile */
}

/* Apply fixed width only on large screens */
@media (min-width: 1024px) {
  .quran-sidebar {
    width: 350px;
  }
}

.quran-sidebar > div {
  /*border-right: 1px solid #e5e7eb; */
  background:none !important;
}

@keyframes custom-pulse {
  0%, 100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 0.8;
    transform: translate(-50%, -50%) scale(0.95);
  }
}

.animate-custom-pulse {
  animation: custom-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.quran-sidebar .sidebar-item:hover {
  background-color: transparent !important;
}

/* Add these styles to override Flowbite dropdown */
[data-testid="flowbite-dropdown"] {
  padding: 0 !important;
  background:none;
  background-color:none !important;
  --tw-shadow:none !important;
  border:none !important;
  border-radius:24px !important;
}

[data-testid="flowbite-dropdown"] > ul {
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  border-radius: 0 !important;
}

[data-testid="flowbite-dropdown"] > ul {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* Calendar Dark Mode Styles */
.dark-calendar .rbc-off-range-bg {
  background: #1A1A1A !important;
}

.dark-calendar .rbc-today {
  background-color: #1f2937 !important;
}

.dark-calendar .rbc-header,
.dark-calendar .rbc-time-header-cell {
  color: #fff !important;
  border-bottom: 1px solid #374151 !important;
}

.dark-calendar .rbc-month-row + .rbc-month-row,
.dark-calendar .rbc-day-bg + .rbc-day-bg,
.dark-calendar .rbc-header + .rbc-header {
  border-left: 1px solid #374151 !important;
}

.dark-calendar .rbc-month-view,
.dark-calendar .rbc-time-view,
.dark-calendar .rbc-agenda-view {
  border: 1px solid #374151 !important;
}

.dark-calendar .rbc-toolbar button {
  color: #fff !important;
  border: 1px solid #374151 !important;
}

.dark-calendar .rbc-toolbar button:hover {
  background-color: #1f2937 !important;
}

.dark-calendar .rbc-toolbar button.rbc-active {
  background-color: #374151 !important;
  border-color: #4B5563 !important;
}

.dark-calendar .rbc-off-range {
  color: #6B7280 !important;
}

.dark-calendar .rbc-date-cell {
  color: #fff !important;
}

.dark-calendar .rbc-time-content {
  border-top: 1px solid #374151 !important;
}

.dark-calendar .rbc-time-header-content {
  border-left: 1px solid #374151 !important;
}

/* Add these styles at the end of the file */
@supports (padding: env(safe-area-inset-bottom)) {
  .has-safe-area {
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);
  }

  .safe-area-bottom {
    padding-bottom: calc(env(safe-area-inset-bottom) / 2);
  }
}

/* Override any padding on the root element */
#root {
  padding: 0 !important;
}

/* Add specific padding for the content area */
.content-area {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out forwards;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-slide-up {
  animation: slideUp 0.3s ease-out forwards;
}

.animate-slide-down {
  animation: slideDown 0.3s ease-out forwards;
}

.animate-fade-in {
  animation: fadeIn 0.4s ease-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 0.6s ease-out forwards;
}

.delay-100 {
  animation-delay: 100ms;
}

.delay-200 {
  animation-delay: 200ms;
}

.delay-300 {
  animation-delay: 300ms;
}