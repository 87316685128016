@font-face {
  font-family: 'KfgqpcHafsUthmanicScriptRegular-1jGEe';
  src: url('../../public/fonts/KfgqpcHafsUthmanicScriptRegular-1jGEe.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.smart-mirror {
  background-color: black;
  color: white;
  min-height: 100vh;
}

.smart-mirror .container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.smart-mirror .flex-grow {
  flex: 1 0 auto;
}

.smart-mirror .mt-auto {
  margin-top: auto;
}

.smart-mirror .card,
.smart-mirror div[class*="card"],
.smart-mirror [class*="flowbite-"],
.smart-mirror [class*="Card"],
.smart-mirror [class*="card"],
.smart-mirror .bg-white {
  background-color: black !important;
  border: none !important;
  box-shadow: none !important;
}

.smart-mirror h1, .smart-mirror h2, .smart-mirror h3, .smart-mirror h4, .smart-mirror h5, .smart-mirror h6 {
  color: white !important;
}

.smart-mirror p, .smart-mirror span, .smart-mirror div {
  color: white !important;
}

.smart-mirror button {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.smart-mirror button:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.smart-mirror input, .smart-mirror select {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

/* Updated progress bar styles for Flowbite */
.smart-mirror .w-full.bg-gray-200 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.smart-mirror .w-full.bg-gray-200 > div {
  background-color: white !important;
  color: black !important;
}

/* Additional styles to remove any remaining borders or backgrounds */
.smart-mirror * {
  border-color: transparent !important;
}

.smart-mirror [class*="flowbite-"],
.smart-mirror [class*="Card"],
.smart-mirror [class*="card"] {
  background-color: black !important;
}

/* Target specific Flowbite components */
.smart-mirror .flowbite-card,
.smart-mirror .flowbite-card-body,
.smart-mirror .flowbite-card-header {
  background-color: black !important;
  color: white !important;
}

/* Override any inline styles */
.smart-mirror [style*="background-color"] {
  background-color: black !important;
}

.smart-mirror [style*="color"] {
  color: white !important;
}

/* Override Tailwind classes */
.smart-mirror .bg-white,
.smart-mirror .bg-gray-50,
.smart-mirror .bg-gray-100,
.smart-mirror .bg-gray-200 {
  background-color: black !important;
}

.smart-mirror .text-gray-500,
.smart-mirror .text-gray-600,
.smart-mirror .text-gray-700,
.smart-mirror .text-gray-800,
.smart-mirror .text-gray-900 {
  color: white !important;
}

/* Ensure text in progress bar is visible */
.smart-mirror .w-full.bg-gray-200 > div {
  color: black !important;
}

/* Center text in Dua component when it's 2 columns wide */
.smart-mirror .col-span-2 #dailyDuaCard * {
  text-align: center !important;
}

/* Style for Uthmanic script in Daily Dua */
.smart-mirror #dailyDuaCard .font-uthmani {
  font-family: 'KfgqpcHafsUthmanicScriptRegular-1jGEe', Arial, sans-serif !important;
  font-size: 2.5rem !important;
  line-height: 1.5 !important;
}